<template>
  <b-container
    class="mb-25"
    :style="
      currentLocale == 'en'
        ? 'font-family: Mark Pro'
        : 'font-family: Noto Kufi Arabic'
    "
    fluid
    :class="currentLocale == 'en' ? 'eng' : 'arabic'"
  >
    <!-- Header -->
    <section class="mb-15">
      <b-row class="header-section">
        <b-col
          class="col-12 col-md-6 p-0"
          v-if="currentLocale == 'ar' || isMobile"
        >
          <div class="banner-image">
            <div class="rounder-arabic">
              <video
                id="vid"
                height="100%"
                width="100%"
                src="media/video/updated.mp4"
                loop
                autoplay
                muted
                playsinline
                type="video/mp4"
              ></video>
            </div>
          </div>
        </b-col>
        <b-col
          align-self="center"
          class="header-section-heading col-12"
          :class="
            currentLocale == 'en' ? 'ltr pl-md-20 col-md-5' : 'rtl col-md-5'
          "
          :style="currentLocale == 'ar' ? '' : ''"
        >
          <!-- <h1>{{ $t("moonLanding.headerSection.h1") }}</h1>
          <br /><br />-->
          <h1 v-if="currentLocale == 'en'">
            Do you have a lot of
            <span class="header-influence">influence</span>
            on social media?
          </h1>
          <h1 v-if="currentLocale == 'ar'">تعد نفسك مؤثر؟</h1>

          <h3 style="font-size: 25px">
            {{ $t("moonLanding.headerSection.h3") }}
          </h3>
          <p>
            {{ $t("moonLanding.headerSection.p") }}
          </p>
          <br />
          <b-button
            class="header-button"
            @click="
              $router.push({
                name: 'join',
                query: { country: country },
              })
            "
            >{{ $t("moonLanding.headerSection.button") }}</b-button
          >
        </b-col>
        <b-col
          class="col-12 col-md-7 p-0 rtl"
          :class="currentLocale == 'en' ? 'ml-auto' : ''"
          v-if="currentLocale == 'en'"
        >
          <div class="banner-image d-none d-md-block">
            <!-- <b-img src="media/svg/moon-assets/Moon - Video - Banner - 1.png" /> -->
            <!-- <div>
              <b-embed
                type="iframe"
                aspect="16by9"
                src="media/video/video_1280.mp4"
                allowfullscreen
              ></b-embed>
            </div> -->
            <div class="rounded-border">
              <video
                id="vid"
                height="100%"
                width="100%"
                src="media/video/updated.mp4"
                loop
                autoplay
                muted
                playsinline
                type="video/mp4"
              ></video>
            </div>
          </div>
        </b-col>
      </b-row>
    </section>

    <!-- advertisment section -->
    <section
      class="adv-section"
      style="background-color: #fde6d6; padding-top: 80px; padding-bottom: 80px"
    >
      <!-- <b-row class="mb-5">
        <b-col class="col-md-3"></b-col>
        <b-col align-self="center" class="col-12 col-md-6 text-center">
          <h1>{{ $t("moonLanding.advSection.h1") }}</h1>
          <h3 class="pl-3 pr-3">
            {{ $t("moonLanding.advSection.h3") }}
          </h3>
        </b-col>
      </b-row>-->

      <b-row align-h="center">
        <b-col
          v-if="currentLocale == 'en'"
          align-self="center"
          class="col-12 col-md-6 text-left"
        >
          <h1 style="text-align: left" class="heading">
            {{ $t("moonLanding.advSection.h1") }}
          </h1>
          <h3 style="text-align: left" class="heading-desc">
            {{ $t("moonLanding.advSection.h3") }}
          </h3>
        </b-col>

        <b-col
          v-if="currentLocale == 'ar' && isMobile"
          align-self="center"
          class="col-12 col-md-5 text-right pl-25 pr-md-0 pr-10"
        >
          <h1 style="text-align: right" class="pl-20 pr-50 pt-20 pt-md-0">
            {{ $t("moonLanding.advSection.h1") }}
          </h1>
          <h3 style="text-align: right" class="pl-20 pr-50">
            {{ $t("moonLanding.advSection.h3") }}
          </h3>
        </b-col>

        <b-col class="col-12 col-md-5">
          <b-row>
            <b-col class="col-md-5 col-5 advertisment-sec">
              <div class="server-action-menu">
                <b-img src="media/svg/moon-assets/calendar.svg" />

                <br />
                <h3>{{ $t("moonLanding.advSection.subHeading1") }}</h3>
              </div>
            </b-col>
            <b-col class="col-md-5 col-5 advertisment-sec">
              <div class="server-action-menu">
                <b-img src="media/svg/moon-assets/follower.svg" />
                <br />
                <h3>{{ $t("moonLanding.advSection.subHeading2") }}</h3>
              </div>
            </b-col>
            <b-col class="col-md-5 col-5 advertisment-sec">
              <div class="server-action-menu">
                <b-img src="media/svg/moon-assets/sm.svg" />
                <br />
                <h3>{{ $t("moonLanding.advSection.subHeading3") }}</h3>
              </div>
            </b-col>
            <b-col class="col-md-5 col-5 advertisment-sec">
              <div class="server-action-menu">
                <b-img src="media/svg/moon-assets/Flat.svg" />
                <br />
                <h3>{{ $t("moonLanding.advSection.subHeading4") }}</h3>
              </div>
            </b-col>
          </b-row>

          <!-- <b-col class="col-md-3 advertisment-sec">
          <b-img src="media/svg/moon-assets/chat.svg" />
          <br />
          <h3>
            {{ $t("moonLanding.advSection.subHeading5") }}
          </h3>
          </b-col>-->
        </b-col>

        <b-col
          v-if="currentLocale == 'ar' && !isMobile"
          align-self="center"
          class="col-12 col-md-5 text-right pl-25 pr-md-0 pr-10"
        >
          <h1 style="text-align: right" class="pl-20 pr-50 pt-20 pt-md-0">
            {{ $t("moonLanding.advSection.h1") }}
          </h1>
          <h3 style="text-align: right" class="pl-20 pr-50">
            {{ $t("moonLanding.advSection.h3") }}
          </h3>
        </b-col>
      </b-row>
    </section>
    <section
      class="guide-section"
      :class="currentLocale == 'en' ? 'ltr' : 'rtl'"
    >
      <b-row class="mb-5">
        <b-col class="col-md-3"></b-col>
        <b-col align-self="center" class="col-12 col-md-6 text-center">
          <h1>{{ $t("moonLanding.guideSection.h1") }}</h1>
        </b-col>
      </b-row>
      <!-- steppers for desktop -->
      <b-row class="d-none d-md-block">
        <b-col cols="12" class="mt-10 mb-10 text-center">
          <b-img
            v-if="currentLocale == 'ar'"
            class="pl-5 pr-5"
            style="max-width: -webkit-fill-available"
            src="media/svg/moon-assets/arabic_stepper.png"
          />
          <b-img
            v-if="currentLocale == 'en'"
            class="pl-5 pr-5"
            style="max-width: -webkit-fill-available"
            src="media/svg/moon-assets/Moon - Graphic - Timeline.png"
          />
        </b-col>
        <!-- <b-col class="mb-5" cols="12">
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step step="1">
                بنتفق عالمواقع اللي حاب تعلن لها و نسبتك من المبیعات
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2">
                بنعطیك الكوبونات للمواقع اللي اخترتها
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                بنحدد لك اوقات محدده تعلن فیها خلال الشهر
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </b-col>

        <b-col cols="12">
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step step="4">
                بنحول لك النسبة خلال 15 یوم من الشهر الجاي
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="5">
                تذكر بالكوبون دایم عشان تزید مبیعات كوبونك
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="6">
                تثبت الكوبون على حساباتك في مواقع التواصل الاجتماعي
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </b-col>-->
      </b-row>

      <!-- steppers for mobile -->

      <b-row class="d-block d-md-none">
        <b-col class="mb-5 p-5" cols="12">
          <b-img
            v-if="currentLocale == 'en'"
            src="media/svg/moon-assets/instruction-vertical.png"
            class="guide-sec-img"
          />
          <b-img
            v-if="currentLocale == 'ar'"
            src="media/svg/moon-assets/instruction-vertical-arabic.png"
            class="guide-sec-img"
          />
          <!-- <v-stepper vertical>
            <v-stepper-step step="1">{{
              $t("moonLanding.guideSection.step1")
            }}</v-stepper-step>

            <v-stepper-step step="2">{{
              $t("moonLanding.guideSection.step2")
            }}</v-stepper-step>

            <v-stepper-step step="3">{{
              $t("moonLanding.guideSection.step3")
            }}</v-stepper-step>

            <v-stepper-step step="4">{{
              $t("moonLanding.guideSection.step4")
            }}</v-stepper-step>

            <v-stepper-step step="5">{{
              $t("moonLanding.guideSection.step5")
            }}</v-stepper-step>

            <v-stepper-step step="6">{{
              $t("moonLanding.guideSection.step6")
            }}</v-stepper-step>
          </v-stepper> -->
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col align-self="center" class="text-center">
          <!-- <b-button
            @click="
              $router.push({
                name: 'join',
                query: { referal: 'true', country: country },
              })
            "
          >
            ابدأ هنا
          </b-button>-->
        </b-col>
      </b-row>
    </section>

    <section class="client-section mb-15">
      <h1 class="text-center">{{ $t("moonLanding.clientSection.h1") }}</h1>
      <b-row class="pl-md-25 pr-md-25">
        <b-col class="col-6 col-md-3">
          <b-img src="media/svg/moon-assets/volga.png" />
        </b-col>
        <b-col class="col-6 col-md-3">
          <b-img src="media/svg/moon-assets/bbw@2x.png" />
        </b-col>
        <b-col class="col-6 col-md-3">
          <b-img src="media/svg/moon-assets/ounass@2x.png" />
        </b-col>
        <b-col class="col-6 col-md-3">
          <b-img src="media/svg/moon-assets/mumz@2x.png" />
        </b-col>
        <b-col class="col-6 col-md-3">
          <b-img src="media/svg/moon-assets/hm@2x.png" />
        </b-col>
        <b-col class="col-6 col-md-3">
          <b-img src="media/svg/moon-assets/sephora@2x.png" />
        </b-col>
        <b-col class="col-6 col-md-3">
          <b-img src="media/svg/moon-assets/ae@2x.png" />
        </b-col>
        <b-col class="col-6 col-md-3">
          <b-img src="media/svg/moon-assets/nayoumi@2x.png" />
        </b-col>
      </b-row>
    </section>

    <!-- Video Slider Section -->
    <!-- <section
      class="video-section"
      :class="currentLocale == 'en' ? 'ltr' : 'rtl'"
    >
      <h1
        style="max-width: 400px"
        :class="currentLocale == 'en' ? 'text-left ml-15' : 'text-right mr-15'"
      >
        {{ $t("moonLanding.videoSliderSection.h1") }}
      </h1>
      <b-row align-h="center" class="m-5">
        <div class="cards-wrapper">
          <div class="card">
            <img src="media/svg/moon-assets/Moon - Video - Banner - 1.png" />
            <span class="card-text card-text-left" v-if="currentLocale == 'en'"
              >What an amazing experience I've had so far!</span
            >
            <span
              class="card-text card-text-right"
              v-if="currentLocale == 'ar'"
            >
              يا لها من تجربة مدهشة لقد كان حتى الآن!
            </span>
            <p v-if="currentLocale == 'en'" class="text-left">
              Ahmed here talks about his experience in working with the program
              for few months.
            </p>
            <p v-if="currentLocale == 'ar'" class="text-right">
              يتحدث أحمد هنا عن تجربته في العمل مع البرنامج لعدة أشهر.
            </p>
          </div>
          <div class="card">
            <img src="media/svg/moon-assets/Moon - Video - Banner - 2.png" />

            <span class="card-text card-text-left" v-if="currentLocale == 'en'"
              >What an amazing experience I've had so far!</span
            >
            <span
              class="card-text card-text-right"
              v-if="currentLocale == 'ar'"
            >
              يا لها من تجربة مدهشة لقد كان حتى الآن!
            </span>
            <p v-if="currentLocale == 'en'" class="text-left">
              Ahmed here talks about his experience in working with the program
              for few months.
            </p>
            <p v-if="currentLocale == 'ar'" class="text-right">
              يتحدث أحمد هنا عن تجربته في العمل مع البرنامج لعدة أشهر.
            </p>
          </div>
          <div class="card">
            <img src="media/svg/moon-assets/Moon - Video - Banner - 1.png" />

            <span class="card-text card-text-left" v-if="currentLocale == 'en'"
              >What an amazing experience I've had so far!</span
            >
            <span
              class="card-text card-text-right"
              v-if="currentLocale == 'ar'"
            >
              يا لها من تجربة مدهشة لقد كان حتى الآن!
            </span>
            <p v-if="currentLocale == 'en'" class="text-left">
              Ahmed here talks about his experience in working with the program
              for few months.
            </p>
            <p v-if="currentLocale == 'ar'" class="text-right">
              يتحدث أحمد هنا عن تجربته في العمل مع البرنامج لعدة أشهر.
            </p>
          </div>
          <div class="card">
            <img src="media/svg/moon-assets/Moon - Video - Banner - 2.png" />

            <span class="card-text card-text-left" v-if="currentLocale == 'en'"
              >What an amazing experience I've had so far!</span
            >
            <span
              class="card-text card-text-right"
              v-if="currentLocale == 'ar'"
            >
              يا لها من تجربة مدهشة لقد كان حتى الآن!
            </span>
            <p v-if="currentLocale == 'en'" class="text-left">
              Ahmed here talks about his experience in working with the program
              for few months.
            </p>
            <p v-if="currentLocale == 'ar'" class="text-right">
              يتحدث أحمد هنا عن تجربته في العمل مع البرنامج لعدة أشهر.
            </p>
          </div>
        </div>
      </b-row>
    </section> -->

    <section class="faq-section" :class="currentLocale == 'en' ? 'ltr' : 'rtl'">
      <h1 class="text-center">{{ $t("moonLanding.faqSection.h1") }}</h1>
      <b-row align-h="center" class="m-5 pl-md-25 pr-md-25">
        <!-- <b-col
          class="col-12 col-md-5 question"
          v-for="(item, i) in $t('moonLanding.faqSection.questionAnswer')"
          :key="i"
        >-->

        <!-- <span class="float-right ml-3">{{ i + 1 }}</span> -->

        <!-- {{ item.ques }}
          <br />
          <span class="answer">{{ item.ans }}</span>
        </b-col>-->

        <!-- test -->

        <v-expansion-panels>
          <v-expansion-panel
            class="expansion-panel"
            v-for="(item, index) in faqList"
            v-bind:key="index"
          >
            <v-expansion-panel-header class="panel-header">
              {{ currentLocale == "en" ? item.question : item.question_arabic }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="panel-content">
              {{ currentLocale == "en" ? item.answer : item.answer_arabic }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- test -->
        <!-- test 2 -->
        <!-- <div class="accordion" role="tablist">
    <b-card no-body class="mb-3">
      <b-card-header header-tag="header" class="faq-btn-header p-1" role="tab">
        <b-button block v-b-toggle.accordion-1 class="faq-btn">Accordion 1</b-button>
      </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text>
          <b-card-text>{{ text }}</b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="info">Accordion 2</b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>{{ text }}</b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="info">Accordion 3</b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>{{ text }}</b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
        </div>-->
        <!-- test 2 -->

        <!-- <b-col class="col-12 col-md-5 question">
          <span class="float-right ml-3">2</span> كیف بتتواصلون معاي؟
          <br />
          <span class="answer">بنتواصل معاك عن طريق رقمك بالواتس اب.</span>
        </b-col>

        <b-col class="col-12 col-md-5 question">
          <span class="float-right ml-3">3</span>
          اقدر یكون عند اكواد باسمي؟
          <br />
          <span class="answer">
            راح نوفر أكواد مخصصة باسم ورقم لك فقط، واسم الكود يكون مرتبط بالحملة
            التسويقية.
          </span>
        </b-col>
        <b-col class="col-12 col-md-5 question">
          <span class="float-right ml-3">4</span>
          كیف اعرف نسبتي من المبیعات؟
          <br />
          <span class="answer">
            نعطيك أول بأول عدد الطلبات اللي حققتها ونرسلك كم نسبتك وصلت من
            المبيعات نهاية الشهر.
          </span>
        </b-col>
        <b-col class="col-12 col-md-5 question">
          <span class="float-right ml-3">5</span> كیف راح استلم فلوسي؟
          <br />
          <span class="answer">
            يتم تحويل نسبتك على حسابك البنكي خلال أول 15 يوم من الشهر.
          </span>
        </b-col>
        <b-col class="col-12 col-md-5 question">
          <span class="float-right ml-3">6</span>
          كم نسبتي من مبیعات الكوبون؟
          <br />
          <span class="answer">
            تختلف النسبة حسب الموقع اللي تختار تعلن له، لما نتواصل بالواتس اب
            بنعطيك هالمعلومات.
          </span>
        </b-col>-->
      </b-row>
    </section>

    <!-- <section
      class="banner-section"
      :style="{
        'background-image': `url('media/svg/moon-assets/moon_image.png')`,
      }"
    >
      <b-row class="text-right h-100 m-2" align-v="center">
        <b-col class="col-12 col-md-11">
          <h1>تحمست؟ حلو عشان تبدأ</h1>
        </b-col>
        <b-col class="col-12 col-md-11">
          <b-button
            @click="
              $router.push({
                name: 'join',
                query: { referal: 'true', country: country },
              })
            "
          >
            ابدأ هنا
          </b-button>
        </b-col>
      </b-row>
    </section>-->
  </b-container>
</template>

<script>
import { SET_LOCALE } from "@/core/services/store/auth.module";

export default {
  name: "MoonLandingPage",
  created() {
    this.playVideo();

    const countryParam = this.$route.query.country;
    const langParam = this.$route.query.lang;
    if (countryParam) {
      // if comes from referal link redirect to referal step.
      this.country = countryParam;
    }
    if (langParam && (langParam == "ar" || langParam == "en")) {
      // if comes language param then set currentLocale according to them.
      this.changeLocale(langParam);
    }
  },
  mounted() {
    // this.playVideo()
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  components: {},
  data() {
    return {
      vidPlay: document.getElementById("vid"),
      isMobile: false,
      country: "",
      text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
      faqList: [
        {
          question: "How can I know how many orders I made?",
          answer: "We will report to you how many orders you have made.",
          question_arabic: " كيف اعرف نسبتي من المبيعات؟",
          answer_arabic: "نعطيك كم طلب جبت نهاية الشهر.",
        },
        {
          question: "How will you contact me?",
          answer: "We will contact you via whatsapp.",
          question_arabic: "كيف بتتواصلون معاي؟",
          answer_arabic: ".بنتواصل معاك عن طريق رقمك بالواتس اب.",
        },
        {
          question: "Can I have a coupon code with my initials or name?",
          answer:
            "You will provide you a coupon code with a name and a number that is specifically for you, the name of the coupon code is related to the campaign.",

          question_arabic: "اقدر يكون عند اكواد باسمي؟",
          answer_arabic:
            ".راح نوفر أكواد مخصصة باسم ورقم لك فقط، واسم الكود يكون مرتبط بالحملة التسويقية",
        },
        {
          question: "How will I know my sales percentage?",
          answer:
            "We will update you regularly on how many orders and sales you have made.",
          question_arabic: "كيف اعرف نسبتي من المبيعات؟",
          answer_arabic:
            "نعطيك أول بأول عدد الطلبات اللي حققتها ونرسلك كم نسبتك وصلت من المبيعات نهاية الشهر.",
        },
        {
          question: "How will I receive my money?",
          answer:
            "We will transfer the money to your bank account on the first 15 days of the month.",
          question_arabic: "كيف راح استلم فلوسي؟",
          answer_arabic:
            "يتم تحويل نسبتك على حسابك البنكي خلال أول 15 يوم من الشهر.",
        },
        {
          question: "How much will I get from coupon sales?",
          answer:
            "It’s different for each site and brand you choose to advertise for, we will give you all the information once we contact you.",
          question_arabic: "كم نسبتي من مبيعات الكوبون؟",
          answer_arabic:
            "تختلف النسبة حسب الموقع اللي تختار تعلن له، لما نتواصل بالواتس اب بنعطيك هالمعلومات.",
        },
      ],
    };
  },
  methods: {
    playVideo() {
      vidPlay.play();
    },
    changeLocale(e) {
      this.$i18n.locale = e;
      localStorage.setItem("locale", e);
      this.$store.dispatch(SET_LOCALE, e);
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },
  computed: {
    currentLocale() {
      return this.$store.getters.getLocale;
    },
  },
};
</script>

<style lang="scss" scoped>
section.guide-section img.guide-sec-img {
  width: 100%;
  margin: 0 auto;
}
section.adv-section h1.heading,
section.adv-section h3.heading-desc {
  padding-left: 80px;
  padding-right: 80px;
}

.rounded-border {
  border-radius: 40px 0px 0px 40px !important;
  video {
    border-radius: 40px 0px 0px 40px !important;
  }
  overflow: hidden;
  -webkit-transform: translateZ(0);
}
.rounder-arabic {
  video {
    // border-radius: 0px 40px 40px 0px !important;
    border-radius: 0px 40px 40px 0px !important;
  }
  // overflow: hidden;
  // -webkit-transform: translateZ(0);
}
.btn {
  background: #324772 0% 0% no-repeat padding-box;
  border-radius: 11px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  width: 140px;
  height: 45px;
  text-transform: uppercase;
}
.row.header-section {
  height: 91vh;
  align-items: center;
}
.header-section {
  // background: #f8f8f8 0% 0% no-repeat padding-box;
  // height: 420px;

  .header-influence {
    // background: -webkit-linear-gradient(269deg, #f5d0b6 0%, #cfa918 100%);
    // background-clip: text;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(269deg, #f5d0b6, #cfa918);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  opacity: 1;
  .banner-image {
    position: relative;
    // height: 440px;
    width: 100%;
    // height: 550px;
    // width: 650px;
  }
  h1 {
    // font: normal normal bold 82px/82px Mark Pro;
    font-family: "Mark Pro";
    // margin-top: 50px;
    // font-weight: 900;
    // font-size: 30px;
    font-size: 50px;
    line-height: 45px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #1c1c1c;

    letter-spacing: 0px;
    color: #000000;
  }
  h2 {
    // font: normal normal 600 35px/40px;
    font-size: 35px;
    line-height: 40px;
    font-weight: 600;
    color: #1c1c1c;
  }
  h3 {
    // color: #5dbe9b;
    // font: normal normal 600 23px/40px;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #000000;

    font-weight: 800;
    line-height: 30px;
    text-transform: uppercase;
  }
  p {
    // font: normal normal 600 18px/30px;
    // font-size: 18px;
    // line-height: 30px;
    // font-weight: 600;
    // letter-spacing: 0px;
    // color: #1c1c1c;
    // margin-left: auto;
    // font: normal normal normal 26px/43px DSLCLU+MarkPro;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;

    font-size: 20px;
    line-height: 35px;
    font-family: "Mark Pro";

    letter-spacing: -1.04px;
    color: #000000;
    opacity: 1;
  }
  img {
    width: 100%;
  }
  .header-button {
    background: #324772 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 36px #9f9f9f26;
    border: 1px solid #707070;
    border-radius: 6px;
    opacity: 1;
    top: 831px;
    left: 90px;
    width: 200px;
    height: 60px;
    text-transform: uppercase;
  }
}
.adv-section-div {
}
.adv-section,
.guide-section,
.client-section,
.faq-section {
  // margin-top: 100px;
  h1 {
    text-align: center;
    // font: normal normal bold 40px/60px;
    font-size: 40px;
    line-height: 60px;
    font-weight: bold;

    letter-spacing: 0px;
    color: #1c1c1c;
  }
  h3 {
    text-align: center;
    // font: normal normal 600 17px/30px;
    font-size: 17px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #1c1c1c;
  }
  .advertisment-sec {
    // padding: 20px;
    // height: 180px;
    // width: 50%;
    background: #fffdfa;
    border-radius: 6px;
    margin: 10px;
    text-align: center;
    background: #fffdfa;

    margin: 10px 5px 10px 20px;
    text-align: center;
    // opacity: 0.1;
    ::v-deep img {
      width: 60px;
      height: 80px;
    }
  }
}
.video-section {
  h1 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #000;
  }
}

.guide-section {
  padding: 60px;
  .v-stepper {
    box-shadow: none;
    ::v-deep i,
    ::v-deep .v-stepper__step__step {
      width: 38px;
      height: 25px;
      background: #cfa918 0% 0% no-repeat padding-box !important;
      color: #fff;
      border-radius: 7px;
    }
    .v-divider {
      border: 7px solid rgb(211 234 249) !important;
      margin-top: 30px;
      border-radius: 8px;
    }
    ::v-deep .v-stepper__label {
      text-align: center;
      // font: normal normal 600 14px/25px;
      font-size: 14px;
      line-height: 25px;
      font-weight: bold;

      letter-spacing: 0px;
      color: #1c1c1c;
    }
  }
}

.client-section {
  img {
    width: 100%;
  }
}

.faq-section {
  // direction: rtl;
  .question {
    background: #e6fbff;
    border-radius: 9px;
    margin: 10px;
    // font: normal normal 600 17px/30px;
    font-size: 17px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    padding: 40px;
  }
  .answer {
    // font: normal normal normal 14px/25px;
    font-size: 14px;
    line-height: 25px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #000000;
  }
}

.banner-section {
  height: 307px;
  background-repeat: round;
  margin: 130px;
  h1 {
    // font: normal normal 600 35px/40px;
    font-size: 35px;
    line-height: 40px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    line-height: 60px;
  }
}

.arabic {
  // font-family: Montserrat-Arabic;
  font-family: "Noto Kufi Arabic", sans-serif;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  div,
  p {
    font-family: "Noto Kufi Arabic";
  }
}
.eng {
  font-family: "Mark Pro";
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  div,
  p {
    font-family: "Mark Pro";
  }
}
// Tablet & Mobile Modes
@media only screen and (max-width: 768px) {
  section.adv-section h1.heading,
  section.adv-section h3.heading-desc {
    padding-left: initial;
    padding-right: initial;
  }
  .guide-section {
    padding: initial;
  }
  .adv-section,
  .guide-section,
  .client-section,
  .faq-section {
    margin-top: 30px;
  }
  .adv-section {
    padding-top: 20px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .rounder-arabic {
    // height: 300px !important;
    video{
    border-radius: 0px !important;
    }
  }

  .header-button {
    width: 100% !important;
    margin-top: 60px;
  }
  .cards-wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .card {
    /*float: left;*/
    min-width: -webkit-fill-available;
    max-width: 33.333%;
    padding: 0.75rem;
    margin-bottom: 2rem;
    border: 0;
    flex-basis: 33.333%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .card > img {
    margin-bottom: 0.75rem;
    width: 100%;
  }

  .card-text {
    // font: normal normal bold 37px/48px Mark Pro;
    font-weight: bold;
    font-style: normal;
    font-variant: normal;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.74px;
    color: #000000;
    opacity: 1;
  }
  .row.header-section {
    height: initial;
  }
  .header-section {
    height: auto;
    .banner-image {
      position: relative;
      width: 100%;
      // height: 300px !important;
    }
    .header-section-heading {
      padding: 30px !important;
    }
    h1 {
      margin-top: 30px;
      // font: normal normal 900 45px/50px Montserrat-Arabic;
      font-size: 35px;
      line-height: 40px;
      font-weight: 700;
      font-family: "Mark Pro";
    }
    h2 {
      // font: normal normal 300 22px/25px Montserrat-Arabic;
      font-size: 22px;
      line-height: 25px;
      font-weight: 300;
    }
    h3 {
      // font: normal normal 300 22px/25px Montserrat-Arabic;
      font-size: 14px !important;
      line-height: 25px;
      font-weight: 600;
    }
    p {
      // font: normal normal 300 14px/25px Montserrat-Arabic;
      font-size: 17px;
      line-height: 25px;
      font-weight: 300;
    }
    .header-section-heading {
      margin: 0px !important;
    }
  }
  .adv-section,
  .faq-section,
  .client-section,
  .guide-section,
  .video-section {
    h1 {
      // font: normal normal bold 30px/39px Montserrat-Arabic;
      font-size: 30px;
      line-height: 39px;
      font-weight: bold;
    }
    h3 {
      // font: normal normal normal 12px/22px Montserrat-Arabic;
      font-size: 12px;
      line-height: 22px;
      font-weight: normal;
    }
    p {
      font-size: 17px;
    }
    .expansion-panel {
      background: #e6fbff 0% 0% no-repeat padding-box !important;
      border-radius: 9px;
      // opacity: 0.4;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .panel-header {
      height: 60px;
      background: #e6fbff 0% 0% no-repeat padding-box;
      border-radius: 9px;
      // opacity: 0.4;
      font-size: 18px;
      justify-content: space-between;
      color: #000000;
      font-size: 14px;
      font-weight: bolder;
    }
    .panel-content {
    }
  }
  .banner-section {
    background-size: cover;
    height: 230px;
    margin: 0;
    background-repeat: no-repeat;
    margin-left: -50px;
    h1 {
      font-size: 15px;
    }
    .btn {
      width: 100px;
      height: 38px;
    }
  }

  .arabic {
    // font-family: Montserrat-Arabic;
    font-family: "Noto Kufi Arabic", sans-serif;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    div,
    p {
      font-family: "Noto Kufi Arabic";
    }
  }
  .eng {
    font-family: "Mark Pro";
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    div,
    p {
      font-family: "Mark Pro";
    }
  }
}
.rtl {
  direction: rtl !important;
  text-align: right;
}
.ltr {
  direction: ltr !important;
  text-align: left;
}
.w-65 {
  width: 65%;
}
.panel-header {
  height: 127px;
  background: #e6fbff 0% 0% no-repeat padding-box;
  border-radius: 9px;
  // opacity: 0.4;
  font-size: 18px;
  justify-content: space-between;
  color: #000000;
  font-weight: 700;
}
.expansion-panel {
  background: #e6fbff 0% 0% no-repeat padding-box !important;
  border-radius: 9px;
  // opacity: 0.4;
  font-size: 18px;
  margin-bottom: 10px;
}
.cards-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.card {
  /*float: left;*/
  max-width: 33.333%;
  padding: 0.75rem;
  margin-bottom: 2rem;
  border: 0;
  flex-basis: 33.333%;
  flex-grow: 0;
  flex-shrink: 0;
}

.card > img {
  margin-bottom: 0.75rem;
  width: 100%;
}

.card-text {
  text-align: left;
  // font: normal normal bold 37px/48px Mark Pro;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
  font-size: 18px;
  font-family: "Mark Pro";
  color: #000000;
  opacity: 1;
}
.card-text-left {
  text-align: left;
}
.card-text-right {
  text-align: right;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #fff;
}
</style>
